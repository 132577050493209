import { createPinia } from "pinia";
import { Controller } from "@hotwired/stimulus";
import { createApp, type App } from "vue";
import { SCOTWidget, type Profile } from "@medware/survey-compare-over-time";

import scotStyles from "@medware/survey-compare-over-time/dist/style.css?inline";

interface Setup {
  profiles: Profile[];
  patientId: string;
}

export default class extends Controller {
  static values = {
    setup: Object
  };

  static targets = ["vue"];

  declare readonly setupValue: Setup | undefined;

  declare readonly vueTarget: HTMLElement;

  #app: App<Element> | undefined;

  connect() {
    this.#app = this.#createVue();
  }

  disconnect() {
    if (this.#app) {
      this.#app.unmount();
    }
  }

  #createVue() {
    const urlParams = new URLSearchParams(window.location.search);
    const selectedProfileId = urlParams.get("selected_schema") || undefined;

    // Avoid leaking styles from the parent app into survey-compare-over-time
    const shadowHost = this.vueTarget;
    const shadowRoot = shadowHost.attachShadow({ mode: "open" });

    const sheet = new CSSStyleSheet();
    sheet.replaceSync(scotStyles);
    shadowRoot.adoptedStyleSheets = [sheet];

    const appContainer = document.createElement("div");
    shadowRoot.appendChild(appContainer);

    const app = createApp(SCOTWidget, {
      apiBaseUrl: "/api",
      profileId: selectedProfileId ? parseInt(selectedProfileId) : undefined,
      patientId: this.setupValue?.patientId,
      profiles: this.setupValue?.profiles,
      teleportContainer: appContainer,
      onProfileChange(profile: Profile) {
        const url = new URL(window.location.href);
        url.searchParams.set("selected_schema", profile.id.toString());
        window.history.pushState({}, "", url.toString());
      }
    });

    app.use(createPinia());
    app.mount(appContainer);

    return app;
  }
}
